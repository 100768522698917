import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { CountryCode as LibCountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';
import { PhoneNumberFormat, parseAndFormatPhoneNumber, unformatPhoneNumber } from '@boels-experience/phone';

abstract class Base {}
export function returnProvider(component: any): any {
  return { provide: Base, useExisting: forwardRef(() => component) };
}

@Directive({
  selector: 'input[boelsInputPhoneFormat]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: BoelsInputPhoneFormatterDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoelsInputPhoneFormatterDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class BoelsInputPhoneFormatterDirective {
  private _value: string | null;
  private _format: PhoneNumberFormat = 'default';

  @Input()
  public set inputFormat(value: PhoneNumberFormat) {
    this._format = value;
  }

  public get inputFormat(): PhoneNumberFormat {
    return this._format;
  }

  @Input() public selectedCountryCode: LibCountryCode;

  @Input('value')
  public get value(): string | null {
    return this._value;
  }

  public set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  constructor(private readonly elementRef: ElementRef<HTMLInputElement>) {}

  /**
   * Evaluate input value and adjust controls & validators value
   * @param value - Text entered by user
   */
  @HostListener('input', ['$event.target.value'])
  public onInput(value: string): void {
    // Cut any non numerical symbols
    this._value = value.replace(/[^\d.-]/g, '');

    try {
      // Parse to check if valid and get number in international format (ex. +31624118016)
      const parsedValue = parsePhoneNumberWithError(this._value, this.selectedCountryCode);

      // Decide which value the control will have and the validators will see
      const controlValue = parsedValue.isValid() ? parsedValue.number : this._value;

      // Notify Angular Validators
      this._onChange(controlValue);
    } catch (e) {
      this._onChange(this._value);
    }
  }

  /**
   * Format value to match local formatting rules when input loses focus
   */
  @HostListener('blur')
  public _onBlur(): void {
    this.formatValue(this._value);
  }

  /**
   * Remove formatting on focus (spaces and so on)
   */
  @HostListener('focus')
  public onFocus(): void {
    this.unFormatValue();
  }

  /**
   * Empty placeholder function, replaced by registerOnChange
   * @param value
   */
  public _onChange(value: any): void {}

  public registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void {}

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(value: any): void {
    this._value = value;
    this.formatValue(this._value);
  }

  /**
   * Format value to match local formatting rules, but updates the value of the html native element
   * @param value
   * @returns
   */
  private formatValue(value: string | null): void {
    if (!value || !this.selectedCountryCode) {
      this.elementRef.nativeElement.value = '';
      return;
    }

    this.elementRef.nativeElement.value = parseAndFormatPhoneNumber(value, this.inputFormat, this.selectedCountryCode);
  }

  /**
   * Remove formatting on focus
   */
  private unFormatValue(): void {
    const value = this.elementRef.nativeElement.value;
    this._value = unformatPhoneNumber(value);
    this.elementRef.nativeElement.value = this._value;
  }
}
