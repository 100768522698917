import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryCode, parsePhoneNumberWithError, PhoneNumber } from 'libphonenumber-js';

export function boelsInputPhoneValidator(component: { selectedCountryCode: CountryCode }): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const error: { validatePhoneNumber: boolean; validatePhoneParseError: boolean; validatePhoneParseMessage: string } =
      { validatePhoneNumber: true, validatePhoneParseError: false, validatePhoneParseMessage: null };
    let numberInstance: PhoneNumber;

    if (control.value) {
      try {
        numberInstance = parsePhoneNumberWithError(control.value, component.selectedCountryCode);
      } catch (e: unknown) {
        error.validatePhoneParseError = true;
        if (e instanceof Error) {
          error.validatePhoneParseMessage = e.message;
        }
        return error;
      }

      if (numberInstance && !numberInstance.isValid()) {
        return error;
      }
    }

    return null;
  };
}
