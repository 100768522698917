import { Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CountryCode, getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { ControlValueAccessorAdapter, QaHooksDirective } from '@boels-experience/core';
import { Country } from '../../models/country.model';
import { PhoneNumberFormat, parseAndFormatPhoneNumber } from '@boels-experience/phone';
import { boelsInputPhoneValidator } from '../../utils/boels-input-phone.validator';
import { CountryCodes, PhoneNumberExamples } from './data/country-code';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CountrySearchPipe } from '../../pipes/country-search.pipe';
import { BoelsInputPhoneFormatterDirective } from '../../directives/boel-input-phone-formatter.directive';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'boels-input-phone',
  templateUrl: './boels-input-phone.component.html',
  styleUrls: ['./boels-input-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoelsInputPhoneComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgClass,
    MatMenuModule,
    FormsModule,
    MatDividerModule,
    MatIconModule,
    CountrySearchPipe,
    BoelsInputPhoneFormatterDirective,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    QaHooksDirective,
    MatButtonModule,
  ],
})
export class BoelsInputPhoneComponent extends ControlValueAccessorAdapter implements OnInit {
  @Input() public inputLabel: string;
  @Input() public inputEnablePlaceholder = true;
  @Input() public inputPlaceholder: string = '';
  @Input() public inputAutofocus: boolean;
  @Input() public inputTabindex: number;
  @Input() public inputPreferredCountries: Array<string> = [];
  @Input() public inputOnlyCountries: Array<string> = [];
  @Input() public inputEnableSearch: boolean = false;
  @Input() public inputSearchPlaceholder: string = 'Search ...';
  @Input()
  public set inputFormat(value: PhoneNumberFormat) {
    this._format = value;
  }
  @Input() public inputHideOptionalMarker: boolean = false;

  public get inputFormat(): PhoneNumberFormat {
    return this._format;
  }

  @Input()
  public set placeholder(value: string) {
    this._placeholder = value;
  }

  public get placeholder(): string {
    const placeHolder = typeof this.selectedCountry?.placeHolder === 'string' ? this.selectedCountry?.placeHolder : '';
    if (placeHolder !== this._placeholderCountry) {
      this._placeholder = '';
      this._placeholderCountry = placeHolder;
    }

    if (this._placeholder) {
      return this._placeholder;
    }

    this._placeholder = parseAndFormatPhoneNumber(
      placeHolder,
      this.inputFormat,
      this.selectedCountry?.iso2.toUpperCase() as CountryCode
    );

    return this._placeholder;
  }

  /**
   * Get the disabled state from the accossiated form control
   * This way we can update the disabled state programmatically from the form
   */
  public get disabled(): boolean {
    return this.control?.disabled;
  }

  public allCountries: Array<Country> = [];
  public preferredCountriesInDropDown: Array<Country> = [];
  public countrySearchValue: string;
  public selectedCountry: Country;
  public selectedCountryCode: CountryCode;

  @ViewChild('focusableElement', { read: ElementRef, static: true }) public focusableElement: ElementRef;

  private _placeholder: string;
  private _placeholderCountry: string;
  private _format: PhoneNumberFormat = 'default';

  public static getPhoneNumberPlaceHolder(countryISOCode: CountryCode): string {
    const result = getExampleNumber(countryISOCode, PhoneNumberExamples);
    return result ? result.number.toString() : undefined;
  }

  constructor() {
    super();
    this.fetchCountryData();
  }

  public onBlur(): void {
    if (!this.control.touched) {
      this.control.markAsTouched();
    }
  }

  public ngOnInit(): void {
    this.control.addValidators(boelsInputPhoneValidator(this));

    if (this.inputPreferredCountries.length) {
      this.inputPreferredCountries.forEach((iso2) => {
        const preferredCountry = this.allCountries.filter((c) => c.iso2 === iso2).shift();
        if (preferredCountry) {
          this.preferredCountriesInDropDown.push(preferredCountry);
        }
      });
    }
    if (this.inputOnlyCountries.length) {
      this.allCountries = this.allCountries.filter((c) => this.inputOnlyCountries.includes(c.iso2));
    }

    const controlValue = typeof this.control?.value === 'string' ? this.control?.value : '';
    const phoneNumber = parsePhoneNumberFromString(controlValue);
    if (phoneNumber) {
      // If an existing number is present, we use it to determine selectedCountry
      // TODO: What if the number is invalid?
      this.selectedCountry = this.getCountry(phoneNumber.country);
    } else {
      if (this.preferredCountriesInDropDown.length) {
        this.selectedCountry = this.preferredCountriesInDropDown[0];
      } else {
        this.selectedCountry = this.allCountries[0];
      }
    }

    this.selectedCountryCode = this.selectedCountry?.iso2.toUpperCase() as CountryCode;
  }

  public onCountrySelect(country: Country): void {
    this.selectedCountry = country;
    this.selectedCountryCode = this.selectedCountry?.iso2.toUpperCase() as CountryCode;

    // Trigger an update of the input value
    const event = new Event('input', { bubbles: true, cancelable: true });
    this.focusableElement.nativeElement.dispatchEvent(event);
  }

  public onFocus(): void {
    this.focusableElement.nativeElement.focus();
  }

  protected fetchCountryData(): void {
    CountryCodes.forEach((c) => {
      const country: Country = {
        name: c[0],
        iso2: c[1],
        dialCode: c[2],
        priority: +c[3] || 0,
        areaCodes: c[4] || undefined,
        flagClass: c[1].toUpperCase(),
        placeHolder: '',
      };

      if (this.inputEnablePlaceholder) {
        country.placeHolder = BoelsInputPhoneComponent.getPhoneNumberPlaceHolder(
          country.iso2.toUpperCase() as CountryCode
        );
      }

      this.allCountries.push(country);
    });
  }

  public getCountry(code: string): Country {
    return (
      this.allCountries.find((c) => c.iso2 === code.toLowerCase()) || {
        name: 'UN',
        iso2: 'UN',
        dialCode: '',
        priority: 0,
        areaCodes: undefined,
        flagClass: 'UN',
        placeHolder: '',
      }
    );
  }
}
