import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';
import { PhoneNumberFormat, parseAndFormatPhoneNumber } from '@boels-experience/phone';

interface FormatPhonePipeArguments {
  format: PhoneNumberFormat;
  countryCode?: CountryCode;
}

@Pipe({
  name: 'formatPhone',
  standalone: true,
})
export class FormatPhonePipe implements PipeTransform {
  public transform(phoneNumber: string, args?: FormatPhonePipeArguments): string {
    const { format, countryCode } = args || {};
    return parseAndFormatPhoneNumber(phoneNumber, format || 'international', countryCode);
  }
}
