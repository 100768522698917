import { PhoneNumberFormat } from '../models/phone-number-format.model';
import { CountryCode, parsePhoneNumberWithError, PhoneNumber } from 'libphonenumber-js';

/**
 * Format a phone number string to a string based on the format provided.
 * @param phoneNumber
 * @param format
 * @param countryCode
 * @returns
 */
export function parseAndFormatPhoneNumber(
  phoneNumber: string,
  format: PhoneNumberFormat = 'international',
  countryCode?: CountryCode
): string {
  let numberInstance: PhoneNumber;

  if (!phoneNumber) {
    return '';
  }

  try {
    numberInstance = parsePhoneNumberWithError(phoneNumber, countryCode);
    return formatPhoneNumber(numberInstance, format);
  } catch (e) {
    return phoneNumber;
  }
}

/**
 * Format a phone number instance to a string based on the format provided.
 * @param phoneNumber
 * @param format
 * @returns
 */
export function formatPhoneNumber(phoneNumber: PhoneNumber, format: PhoneNumberFormat): string {
  switch (format) {
    case 'national':
      return phoneNumber.formatNational();
    case 'international':
      return phoneNumber.formatInternational();
    default:
      return phoneNumber.nationalNumber.toString();
  }
}

/**
 * Remove all non-numeric characters from a phone number string.
 * @param phoneNumber
 * @returns
 */
export function unformatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/[^\d+]/g, '');
}
