import { Pipe, PipeTransform } from '@angular/core';

import { Country } from '../models/country.model';

@Pipe({
  name: 'countrySearch',
  standalone: true,
})
export class CountrySearchPipe implements PipeTransform {
  // country | countrySearch:'countrySearchValue'
  public transform(country: Country, countrySearchValue?: string): boolean {
    if (!countrySearchValue || countrySearchValue === '') {
      return true;
    }

    return `${country.name}+${country.dialCode}`.toLowerCase().includes(countrySearchValue.toLowerCase());
  }
}
